<template>
  <div class="animated fadeIn">
    <b-card header="Long Loop (R7 – R9): Aplicación útil de materiales">
      <b-row>
        <b-col md="12">
          <b-card header="R7: Reciclar Materiales">
            <b-row>
              <b-col md="6">
                <b-card>
                  <div slot="header">
                    Emisiones de Gases de Efecto Invernadero evitadas debido al reciclaje
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r7-1 :height="200" class="chart-wrapper"></r7-1>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card>
                  <div slot="header">
                    Tasa de reciclabilidad de los productos
                    <div class="card-header-actions">
                      <b-badge variant="success">Ecodiseño</b-badge>
                    </div>
                  </div>
                  <r7-2 :height="200" class="chart-wrapper"></r7-2>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card header="R8: Recuperar">
            <b-row>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Tasa de recuperación de residuos o embalajes provenientes de productos de la compañía o procesos
                    productivos
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r8-1 :height="250" class="chart-wrapper"></r8-1>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Toneladas de materias primas utilizadas
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r8-2 :height="250" class="chart-wrapper"></r8-2>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Toneladas de residuos orgánicos
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r8-3 :height="250" class="chart-wrapper"></r8-3>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Toneladas de residuos inorgánicos
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r8-4 :height="250" class="chart-wrapper"></r8-4>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Cálculo del ahorro de combustibles fósiles y CO2 evitado por los clientes debido al uso de productos
                    que provienen de la reutilización o reparación
                    <div class="card-header-actions">
                      <b-badge variant="success">Vida útil</b-badge>
                    </div>
                  </div>
                  <r8-5 :height="250" class="chart-wrapper"></r8-5>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Porcentaje de energías renovables y biomasa en el mix energético
                    <div class="card-header-actions">
                      <b-badge variant="success">Cadena de Valor</b-badge>
                    </div>
                  </div>
                  <r8-6 :height="250" class="chart-wrapper"></r8-6>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Cantidad de materiales y energía intercambiadas con otras empresas o grupos de interés
                    <div class="card-header-actions">
                      <b-badge variant="success">Simbiosis Industrial</b-badge>
                    </div>
                  </div>
                  <r8-7 :height="250" class="chart-wrapper"></r8-7>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Nº Incorporación de instrumentos internos para impulsarla economía circular
                    <div class="card-header-actions">
                      <b-badge variant="success">Producción y consumo responsable</b-badge>
                    </div>
                  </div>
                  <r8-8 :height="250" class="chart-wrapper"></r8-8>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Nº Implantación de la recogida selectiva de residuos en centros de trabajo
                    <div class="card-header-actions">
                      <b-badge variant="success">Producción y consumo responsable</b-badge>
                    </div>
                  </div>
                  <r8-9 :height="250" class="chart-wrapper"></r8-9>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card header="R9: Reminar">
            <b-row>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Tasa de valorización de residuos
                    <div class="card-header-actions">
                      <b-badge variant="success">Eficiencia</b-badge>
                    </div>
                  </div>
                  <r9-1 :height="250" class="chart-wrapper"></r9-1>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Porcentaje de materias primas de fuentes renovables en la composición de los productos vendidos
                    <div class="card-header-actions">
                      <b-badge variant="success">Cadena de Valor</b-badge>
                    </div>
                  </div>
                  <r9-2 :height="250" class="chart-wrapper"></r9-2>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Porcentaje de materiales reciclados en los productos provenientes de proveedores
                    <div class="card-header-actions">
                      <b-badge variant="success">Cadena de Valor</b-badge>
                    </div>
                  </div>
                  <r9-3 :height="250" class="chart-wrapper"></r9-3>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Porcentaje de materias primas secundarias utilizadas para la producción
                    <div class="card-header-actions">
                      <b-badge variant="success">Simbiosis Industrial</b-badge>
                    </div>
                  </div>
                  <r9-4 :height="250" class="chart-wrapper"></r9-4>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Porcentaje de aguas residuales tratadas reutilizadas
                    <div class="card-header-actions">
                      <b-badge variant="success">Simbiosis Industrial</b-badge>
                    </div>
                  </div>
                  <r9-5 :height="250" class="chart-wrapper"></r9-5>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card>
                  <div slot="header">
                    Toneladas enviadas a Vertederos
                    <div class="card-header-actions">
                      <b-badge variant="success">Producción y consumo responsable</b-badge>
                    </div>
                  </div>
                  <r9-6 :height="250" class="chart-wrapper"></r9-6>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
    import CardLine1ChartExample from './dashboard/CardLine1ChartExample'
    import CardLine2ChartExample from './dashboard/CardLine2ChartExample'
    import CardLine3ChartExample from './dashboard/CardLine3ChartExample'
    import CardBarChartExample from './dashboard/CardBarChartExample'
    import MainChartExample from './dashboard/MainChartExample'
    import SocialBoxChartExample from './dashboard/SocialBoxChartExample'
    import CalloutChartExample from './dashboard/CalloutChartExample'
    import {Callout} from '@coreui/vue'
    import Siembra from "./indicadores/produccion/Siembra";
    import Cosecha from "./indicadores/produccion/Cosecha";
    import Procesamiento from "./indicadores/produccion/Procesamiento";
    import Mortalidad from "./indicadores/produccion/Mortalidad";
    import Antibioticos from "./indicadores/produccion/Antibioticos";
    import RelacionAntibioticos from "./indicadores/produccion/RelacionAntibioticos";
    import Alimentos from "./indicadores/produccion/Alimentos";
    import AlimentosConsumidos from "./indicadores/produccion/AlimentosConsumidos";
    import Sanciones from "./indicadores/legal/Sanciones";
    import Ambiental from "./indicadores/legal/Ambiental";
    import Laboral from "./indicadores/legal/Laboral";
    import Municipal from "./indicadores/legal/Municipal";
    import Deposiciones from "./indicadores/biodiversidad/Deposiciones";
    import Reciclaje from "./indicadores/agua/Reciclaje";
    import Psicicultura from "./indicadores/agua/Psicicultura";
    import Chaparro from "./indicadores/agua/Chaparro";
    import Molco from "./indicadores/agua/Molco";
    import Fonck from "./indicadores/agua/Fonck";
    import UsoPlantas from "./indicadores/agua/UsoPlantas";
    import Residuos from "./indicadores/residuos/TotalResiduos";
    import Generadores from "./indicadores/residuos/Generadores";
    import Relleno from "./indicadores/residuos/Relleno";
    import GestionReciclaje from "./indicadores/residuos/GestionReciclaje";
    import TotalEmisiones from "./indicadores/emisiones/TotalEmisiones";
    import Alcances from "./indicadores/emisiones/Alcances";
    import Huella from "./indicadores/emisiones/Huella";
    import Trabajadores from "./indicadores/empleo/Trabajadores";
    import Sexo from "./indicadores/empleo/Sexo";
    import Sindicato from "./indicadores/empleo/Sindicato";
    import Accidentes from "./indicadores/empleo/Accidentes";
    import Fatalidad from "./indicadores/empleo/Fatalidad";
    import TotalCompras from "./indicadores/proveedores/TotalCompras";
    import PorcentajeCompras from "./indicadores/proveedores/PorcentajeCompras";
    import TotalInversion from "./indicadores/comunidad/TotalInversion";
    import Inversiones from "./indicadores/comunidad/Inversiones";
    import R71 from "./indicadores/longloop/r7-1";
    import R72 from "./indicadores/longloop/r7-2";
    import R81 from "./indicadores/longloop/r8-1";
    import R82 from "./indicadores/longloop/r8-2";
    import R83 from "./indicadores/longloop/r8-3";
    import R84 from "./indicadores/longloop/r8-4";
    import R85 from "./indicadores/longloop/r8-5";
    import R86 from "./indicadores/longloop/r8-6";
    import R87 from "./indicadores/longloop/r8-7";
    import R88 from "./indicadores/longloop/r8-8";
    import R89 from "./indicadores/longloop/r8-9";
    import R91 from "./indicadores/longloop/r9-1";
    import R92 from "./indicadores/longloop/r9-2";
    import R93 from "./indicadores/longloop/r9-3";
    import R94 from "./indicadores/longloop/r9-4";
    import R95 from "./indicadores/longloop/r9-5";
    import R96 from "./indicadores/longloop/r9-6";


    export default {
        name: 'biodiversidad',
        components: {
            R96,
            R95,
            R94,
            R93,
            R92,
            R91,
            R89,
            R88,
            R87,
            R86,
            R85,
            R84,
            R83,
            R82,
            R81,
            R72,
            R71,
            Inversiones,
            TotalInversion,
            PorcentajeCompras,
            TotalCompras,
            Fatalidad,
            Accidentes,
            Sindicato,
            Sexo,
            Trabajadores,
            Huella,
            Alcances,
            TotalEmisiones,
            GestionReciclaje,
            Relleno,
            Generadores,
            Residuos,
            UsoPlantas,
            Fonck,
            Molco,
            Chaparro,
            Psicicultura,
            Reciclaje,
            Deposiciones,
            Municipal,
            Laboral,
            Ambiental,
            Sanciones,
            AlimentosConsumidos,
            Alimentos,
            RelacionAntibioticos,
            Antibioticos,
            Mortalidad,
            Procesamiento,
            Cosecha,
            Siembra,
            Callout,
            CardLine1ChartExample,
            CardLine2ChartExample,
            CardLine3ChartExample,
            CardBarChartExample,
            MainChartExample,
            SocialBoxChartExample,
            CalloutChartExample
        },
        data: function () {
            return {
                selected: 'Month',
                tableItems: [
                    {
                        avatar: {url: 'img/avatars/1.jpg', status: 'success'},
                        user: {name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'USA', flag: 'us'},
                        usage: {value: 50, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Mastercard', icon: 'fa fa-cc-mastercard'},
                        activity: '10 sec ago'
                    },
                    {
                        avatar: {url: 'img/avatars/2.jpg', status: 'danger'},
                        user: {name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015'},
                        country: {name: 'Brazil', flag: 'br'},
                        usage: {value: 22, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Visa', icon: 'fa fa-cc-visa'},
                        activity: '5 minutes ago'
                    },
                    {
                        avatar: {url: 'img/avatars/3.jpg', status: 'warning'},
                        user: {name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'India', flag: 'in'},
                        usage: {value: 74, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Stripe', icon: 'fa fa-cc-stripe'},
                        activity: '1 hour ago'
                    },
                    {
                        avatar: {url: 'img/avatars/4.jpg', status: ''},
                        user: {name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'France', flag: 'fr'},
                        usage: {value: 98, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'PayPal', icon: 'fa fa-paypal'},
                        activity: 'Last month'
                    },
                    {
                        avatar: {url: 'img/avatars/5.jpg', status: 'success'},
                        user: {name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'Spain', flag: 'es'},
                        usage: {value: 22, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Google Wallet', icon: 'fa fa-google-wallet'},
                        activity: 'Last week'
                    },
                    {
                        avatar: {url: 'img/avatars/6.jpg', status: 'danger'},
                        user: {name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'Poland', flag: 'pl'},
                        usage: {value: 43, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Amex', icon: 'fa fa-cc-amex'},
                        activity: 'Last week'
                    }
                ],
                tableFields: {
                    avatar: {
                        label: '<i class="icon-people"></i>',
                        class: 'text-center'
                    },
                    user: {
                        label: 'User'
                    },
                    country: {
                        label: 'Country',
                        class: 'text-center'
                    },
                    usage: {
                        label: 'Usage'
                    },
                    payment: {
                        label: 'Payment method',
                        class: 'text-center'
                    },
                    activity: {
                        label: 'Activity'
                    }
                }
            }
        },
        methods: {
            variant(value) {
                let $variant
                if (value <= 25) {
                    $variant = 'info'
                } else if (value > 25 && value <= 50) {
                    $variant = 'success'
                } else if (value > 50 && value <= 75) {
                    $variant = 'warning'
                } else if (value > 75 && value <= 100) {
                    $variant = 'danger'
                }
                return $variant
            },
            flag(value) {
                return 'flag-icon flag-icon-' + value
            }
        }
    }
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
