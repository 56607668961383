<template>
  <div class="animated fadeIn">
    <b-card header="Empleo">
      <b-row>
        <b-col md="3">
          <b-card header="Total de Trabajadores">
            <trabajadores :height="300" class="chart-wrapper"></trabajadores>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Trabajores por Sexo">
            <sexo :height="300" class="chart-wrapper"></sexo>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Sindicato (2018)">
            <sindicato :height="300" class="chart-wrapper"></sindicato>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Tasa de Accidentabilidad">
            <accidentes :height="300" class="chart-wrapper"></accidentes>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Tasa de Fatalidad">
            <fatalidad :height="300" class="chart-wrapper"></fatalidad>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
    import CardLine1ChartExample from './dashboard/CardLine1ChartExample'
    import CardLine2ChartExample from './dashboard/CardLine2ChartExample'
    import CardLine3ChartExample from './dashboard/CardLine3ChartExample'
    import CardBarChartExample from './dashboard/CardBarChartExample'
    import MainChartExample from './dashboard/MainChartExample'
    import SocialBoxChartExample from './dashboard/SocialBoxChartExample'
    import CalloutChartExample from './dashboard/CalloutChartExample'
    import {Callout} from '@coreui/vue'
    import Siembra from "./indicadores/produccion/Siembra";
    import Cosecha from "./indicadores/produccion/Cosecha";
    import Procesamiento from "./indicadores/produccion/Procesamiento";
    import Mortalidad from "./indicadores/produccion/Mortalidad";
    import Antibioticos from "./indicadores/produccion/Antibioticos";
    import RelacionAntibioticos from "./indicadores/produccion/RelacionAntibioticos";
    import Alimentos from "./indicadores/produccion/Alimentos";
    import AlimentosConsumidos from "./indicadores/produccion/AlimentosConsumidos";
    import Sanciones from "./indicadores/legal/Sanciones";
    import Ambiental from "./indicadores/legal/Ambiental";
    import Laboral from "./indicadores/legal/Laboral";
    import Municipal from "./indicadores/legal/Municipal";
    import Deposiciones from "./indicadores/biodiversidad/Deposiciones";
    import Reciclaje from "./indicadores/agua/Reciclaje";
    import Psicicultura from "./indicadores/agua/Psicicultura";
    import Chaparro from "./indicadores/agua/Chaparro";
    import Molco from "./indicadores/agua/Molco";
    import Fonck from "./indicadores/agua/Fonck";
    import UsoPlantas from "./indicadores/agua/UsoPlantas";
    import Residuos from "./indicadores/residuos/TotalResiduos";
    import Generadores from "./indicadores/residuos/Generadores";
    import Relleno from "./indicadores/residuos/Relleno";
    import GestionReciclaje from "./indicadores/residuos/GestionReciclaje";
    import TotalEmisiones from "./indicadores/emisiones/TotalEmisiones";
    import Alcances from "./indicadores/emisiones/Alcances";
    import Huella from "./indicadores/emisiones/Huella";
    import Trabajadores from "./indicadores/empleo/Trabajadores";
    import Sexo from "./indicadores/empleo/Sexo";
    import Sindicato from "./indicadores/empleo/Sindicato";
    import Accidentes from "./indicadores/empleo/Accidentes";
    import Fatalidad from "./indicadores/empleo/Fatalidad";
    import TotalCompras from "./indicadores/proveedores/TotalCompras";
    import PorcentajeCompras from "./indicadores/proveedores/PorcentajeCompras";
    import TotalInversion from "./indicadores/comunidad/TotalInversion";
    import Inversiones from "./indicadores/comunidad/Inversiones";


    export default {
        name: 'empleo',
        components: {
            Inversiones,
            TotalInversion,
            PorcentajeCompras,
            TotalCompras,
            Fatalidad,
            Accidentes,
            Sindicato,
            Sexo,
            Trabajadores,
            Huella,
            Alcances,
            TotalEmisiones,
            GestionReciclaje,
            Relleno,
            Generadores,
            Residuos,
            UsoPlantas,
            Fonck,
            Molco,
            Chaparro,
            Psicicultura,
            Reciclaje,
            Deposiciones,
            Municipal,
            Laboral,
            Ambiental,
            Sanciones,
            AlimentosConsumidos,
            Alimentos,
            RelacionAntibioticos,
            Antibioticos,
            Mortalidad,
            Procesamiento,
            Cosecha,
            Siembra,
            Callout,
            CardLine1ChartExample,
            CardLine2ChartExample,
            CardLine3ChartExample,
            CardBarChartExample,
            MainChartExample,
            SocialBoxChartExample,
            CalloutChartExample
        },
        data: function () {
            return {
                selected: 'Month',
                tableItems: [
                    {
                        avatar: {url: 'img/avatars/1.jpg', status: 'success'},
                        user: {name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'USA', flag: 'us'},
                        usage: {value: 50, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Mastercard', icon: 'fa fa-cc-mastercard'},
                        activity: '10 sec ago'
                    },
                    {
                        avatar: {url: 'img/avatars/2.jpg', status: 'danger'},
                        user: {name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015'},
                        country: {name: 'Brazil', flag: 'br'},
                        usage: {value: 22, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Visa', icon: 'fa fa-cc-visa'},
                        activity: '5 minutes ago'
                    },
                    {
                        avatar: {url: 'img/avatars/3.jpg', status: 'warning'},
                        user: {name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'India', flag: 'in'},
                        usage: {value: 74, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Stripe', icon: 'fa fa-cc-stripe'},
                        activity: '1 hour ago'
                    },
                    {
                        avatar: {url: 'img/avatars/4.jpg', status: ''},
                        user: {name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'France', flag: 'fr'},
                        usage: {value: 98, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'PayPal', icon: 'fa fa-paypal'},
                        activity: 'Last month'
                    },
                    {
                        avatar: {url: 'img/avatars/5.jpg', status: 'success'},
                        user: {name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'Spain', flag: 'es'},
                        usage: {value: 22, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Google Wallet', icon: 'fa fa-google-wallet'},
                        activity: 'Last week'
                    },
                    {
                        avatar: {url: 'img/avatars/6.jpg', status: 'danger'},
                        user: {name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015'},
                        country: {name: 'Poland', flag: 'pl'},
                        usage: {value: 43, period: 'Jun 11, 2015 - Jul 10, 2015'},
                        payment: {name: 'Amex', icon: 'fa fa-cc-amex'},
                        activity: 'Last week'
                    }
                ],
                tableFields: {
                    avatar: {
                        label: '<i class="icon-people"></i>',
                        class: 'text-center'
                    },
                    user: {
                        label: 'User'
                    },
                    country: {
                        label: 'Country',
                        class: 'text-center'
                    },
                    usage: {
                        label: 'Usage'
                    },
                    payment: {
                        label: 'Payment method',
                        class: 'text-center'
                    },
                    activity: {
                        label: 'Activity'
                    }
                }
            }
        },
        methods: {
            variant(value) {
                let $variant
                if (value <= 25) {
                    $variant = 'info'
                } else if (value > 25 && value <= 50) {
                    $variant = 'success'
                } else if (value > 50 && value <= 75) {
                    $variant = 'warning'
                } else if (value > 75 && value <= 100) {
                    $variant = 'danger'
                }
                return $variant
            },
            flag(value) {
                return 'flag-icon flag-icon-' + value
            }
        }
    }
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
