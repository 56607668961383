<script>
    import {Bar} from 'vue-chartjs'
    import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips'
    import {hexToRgba} from '@coreui/coreui/dist/js/coreui-utilities'

    export default {
        components: {
            hexToRgba,
            CustomTooltips
        },
        extends: Bar,
        mounted() {
            this.renderChart(
                {
                    labels: ['2017', '2018'],
                    datasets: [
                        {
                            label: 'Tasa',
                            backgroundColor: hexToRgba('#fdff00', 70),
                            fill: true,
                            data: [40, 70]
                        }
                    ]
                },
                {
                    responsive: true,
                    scales: {
                        yAxes: [{
                            stacked: false,
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                                suggestedMax: 10
                            }
                        }]
                    },
                    maintainAspectRatio: true,
                    tooltips: {
                        enabled: false,
                        custom: CustomTooltips,
                        intersect: false,
                        mode: 'index',
                        position: 'nearest',
                        callbacks: {
                            labelColor: function (tooltipItem, chart) {
                                return {backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor}
                            }
                        }
                    }
                }
            )
        }
    }
</script>
